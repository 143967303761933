import React from "react";
import dayjs from 'dayjs'
import { Card, Button } from 'tabler-react'
import { useResource } from "../services/engine";

export const Messages = () => {
  const { update, listData } = useResource(
    "messages",
    `
        id
        content
        created_at
      `,
    `
        id
      `,
    {
      where: {
        read: {
          _eq: false,
        },
      },
    }
  );

  if (!listData) return <div>Ładowanie...</div>;
  return (
    <div>
      {listData.map((message) => {
        const {
          name,
          phone,
          street,
          houseNumber,
          flatNumber,
          bags,
          bagType,
          from,
          text
        } = JSON.parse(message.content)
        return <Card>
          <Card.Header>
            <Card.Title>{from ? 'SMS' : "Formularz"} ({dayjs(message.created_at).format('YYYY-MM-DD HH:mm')})</Card.Title>
            <Card.Options>
              <Button color="primary" size="sm" onClick={() =>
              update(message.id, {
                read: true,
              })
            }>
                Usuń
              </Button>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <ul>
              {from && <li>Od: <b>{from}</b></li>}
              {name && <li>Imię i nazwisko: <b>{name}</b></li>}
              {phone && <li>Telefon: <b>{phone}</b></li>}
              {street && <li>Ulica: <b>{street}</b></li>}
              {houseNumber && <li>Number domu: <b>{houseNumber}</b></li>}
              {flatNumber && <li>Numer mieszkania: <b>{flatNumber}</b></li>}
              {bags && <li>Worków: <b>{bags}</b></li>}
              {bagType && <li>Typ worka: <b>{bagType}</b></li>}
              {text && <li>Treść: <b>{text}</b></li>}
            </ul>
          </Card.Body>
        </Card>
      })}
    </div>
  );
};

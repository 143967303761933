import React, { useEffect, useState } from 'react'
import ky from 'ky';
import {reverse} from 'ramda'
import dayjs from 'dayjs'
import { Table } from 'tabler-react';
import { microAddress } from '../services/consts';

export default () => {
    const [data, setData] = useState(false)
    const loadData = () => {
        ky.get(microAddress + '/ordersToSend').json().then(data => {
            setData(data)
            console.log(data)
        })
    }

    useEffect(loadData, [])
    const send = id => () => {
        ky.get(microAddress + '/endOrder?id=' + id).then(loadData)
    }

    if (!data) return null
    return <>
    <Table>
        <Table.Header>
          <Table.ColHeader>Produkty</Table.ColHeader>
          <Table.ColHeader>Rzeczy</Table.ColHeader>
          <Table.ColHeader>Akcje</Table.ColHeader>
        </Table.Header>
        <Table.Body>
          {reverse(data).map(order => (
            <Table.Row key={order.order_id}>
                <Table.Col>
                    [{dayjs(order.date_add * 1000).format('YYYY-MM-DD')}]&nbsp;
                    {order.delivery_fullname}
                    {order.products.map(product => <div>{product.name} - {product.quantity}</div>)}
                </Table.Col>
                <Table.Col>
                    <a href={microAddress + "/getLabel?id=" + order.order_id} target='_blank'>Etykieta</a>, 
                    <a href={"https://www.fakturowo.pl/pobierz/" + order.extra_field_1} target='_blank'>Faktura</a>, 
                    <a href={"https://www.fakturowo.pl/pobierz/" + order.extra_field_2} target='_blank'>WZ</a>
                </Table.Col>
                <Table.Col>
                    <div onClick={send(order.order_id)}>Wyślij</div>
                </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
}
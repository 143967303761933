import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React from "react";
import { useParams } from "react-router-dom";

dayjs.extend(isSameOrAfter).extend(isSameOrBefore);

export const Report = () => {
  const earningsPerBag = 150;
  const defaultDriverDay = 50;
  const defaultDriverBag = 20;

  const cities = ["Warszawa", "Łódź", "Kraków"];
  const defaultDriverCity = cities[0];

  const { start, end } = useParams();
  const { data } = useQuery(gql`{
        users {
            id
            name
            role
            city
          }
          costs {
            data
            month
            year
          }
          orders(where: {status: {_eq: "Done"}, updated_at: {_gte: "${start}", _lte: "${end}"}}) {
            bags
            finish_date
            driver_id
          }
    }`);

  if (!data) return <div>Ładowanie...</div>;

  const { users, costs, orders } = data;
  const drivers = users
    .filter(({ role }) => role === "driver")
    .map((driver) => ({
      ...driver,
      city: driver.city || defaultDriverCity,
      bagcost: driver.bagcost || defaultDriverBag,
      daycost: driver.daycost || defaultDriverDay,
    }));

  function* generateMonths() {
    let moveable = dayjs(start);
    yield moveable;
    while (moveable.format("YYYY-MM") !== dayjs(end).format("YYYY-MM")) {
      moveable = moveable.add(1, "month");
      yield moveable;
    }
  }
  const generator = generateMonths();

  const allMonthYearsInRange = [...generator].map((el) => el.format("YYYY-MM"));
  console.log(allMonthYearsInRange);
  const allCostsSetsInRange = costs.filter(({ month, year }) =>
    allMonthYearsInRange.includes(`${year}-${month}`)
  );
  const allCostsInRange = allCostsSetsInRange
    .map(({ data, month, year }) =>
      data.map((cost) => ({
        ...cost,
        month,
        year,
      }))
    )
    .flat()
    .filter(
      ({ month, year, day }) =>
        dayjs(`${year}-${month}-${day}`).isSameOrAfter(start) &&
        dayjs(`${year}-${month}-${day}`).isSameOrBefore(end)
    );
  const calculateCityCosts = (city) => {
    const cityCosts = allCostsInRange
      .filter((el) => el.region === city)
      .map((cost) => ({
        ...cost,
        category: drivers.find(({ name }) => cost.desc.includes(name))
          ? drivers.find(({ name }) => cost.desc.includes(name)).id
          : "other",
      }));
    console.log(city);
    console.log(cityCosts);
    return {
      city,
      fullCosts: cityCosts,
      other: cityCosts.filter(({ category }) => category === "other"),
      drivers: drivers
        .filter((driver) => driver.city === city)
        .map((driver) => ({
          ...driver,
          bagsNumber: orders
            .filter(({ driver_id }) => driver_id === driver.id)
            .reduce((a, b) => a + b.bags, 0),
          costs: cityCosts.filter(
            ({ category, genre }) =>
              category === driver.id && genre !== "earning"
          ),
          earnings: cityCosts.filter(
            ({ category, genre }) =>
              category === driver.id && genre === "earning"
          ),
        })),
    };
  };

  const driverCost = (driver) => {
    const days = dayjs(end).diff(start, "days") * driver.daycost;
    const bags = driver.bagsNumber * driver.bagcost;
    return days + bags;
  };

  const driversWorth = (driver) => {
    console.log(driver.bagsNumber);
    return driver.bagsNumber * earningsPerBag;
  };

  const sums = (city) => {
    return {
      ...city,
      costs:
        city.fullCosts
          .filter((el) => el.genre !== "earning")
          .reduce((a, b) => a + parseFloat(b.netto), 0) +
        city.drivers.map(driverCost).reduce((a, b) => a + b, 0),
      revenue:
        city.fullCosts
          .filter((el) => el.genre === "earning")
          .reduce((a, b) => a + parseFloat(b.netto), 0) +
        city.drivers.map(driversWorth).reduce((a, b) => a + b, 0),
    };
  };

  const calculatedCities = cities.map(calculateCityCosts).map(sums);

  return (
    <div>
      <div>
        <h2>
          {start} - {end}
        </h2>
      </div>
      <table>
        <thead>
          <tr>
            <th>Lokacja</th>
            <th>Koszty</th>
            <th>Przychody</th>
            <th>Bilans</th>
          </tr>
        </thead>
        <tbody>
          {calculatedCities.map((city) => (
            <>
              <tr>
                <td>{city.city}</td>
                <td>{city.costs}</td>
                <td>{city.revenue}</td>
                <td>{city.revenue - city.costs}</td>
              </tr>
              <tr>
                <td>&nbsp;Inne</td>
                <td>
                  {city.other
                    .filter((el) => el.genre !== "earning")
                    .reduce((a, b) => a + parseFloat(b), 0)}
                </td>
                <td>
                  {city.other
                    .filter((el) => el.genre === "earning")
                    .reduce((a, b) => a + parseFloat(b), 0)}
                </td>
                <td>
                  {city.other
                    .filter((el) => el.genre === "earning")
                    .reduce((a, b) => a + parseFloat(b), 0) -
                    city.other
                      .filter((el) => el.genre === "earning")
                      .reduce((a, b) => a + parseFloat(b), 0)}
                </td>
              </tr>
              {city.drivers.map((driver, ind) => (
                <tr>
                  <td>&nbsp;{driver.name || "Kierowca " + ind}</td>
                  <td>{driverCost(driver)}</td>
                  <td>{driversWorth(driver)}</td>
                  <td>{driversWorth(driver) - driverCost(driver)}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

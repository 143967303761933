import React, { useState } from 'react'
import { Form, Button } from "tabler-react";
import ky from "ky";

export default ({set}) => {

    const authClient = ky.create({
        prefixUrl: 'https://auth.gruzbag.krzysztofsikorski.pl'
    });

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const doLogin = async () => {
        const JWT = await authClient
            .post("login", {
            json: {
                login: login,
                password: password
            }
            })
            .text();

        console.log(JWT)

        set(JWT)
    }

    const doRegister = async () => {
        const JWT = await authClient
            .post("register", {
            json: {
                login: login,
                password: password
            }
            })
            .text();

        console.log(JWT)

        set(JWT)
    }

    return <div className='fixed inset-0 flex justify-center items-center'>
      <div className='p-8 border-gray-200 border-2 rounded border-solid'>
        <div className='text-xl mb-4'>Zaloguj się</div>
        <Form.Group label="Email">
          <Form.InputGroup>
            <Form.Input
              placeholder="Wpisz email..."
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
          </Form.InputGroup>
        </Form.Group>
        <Form.Group label="Haslo">
          <Form.InputGroup>
            <Form.Input
            type='password'
              placeholder="Wpisz hasło..."
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.InputGroup>
        </Form.Group>
   <Button color="primary" onClick={doLogin}>Zaloguj się</Button>
      </div> 
    </div>
  }


//   <form onSubmit={e => e.preventDefault()}>
//   <input type='text' value={login} onChange={e => setLogin(e.target.value)} /><br />
//   <input type='password' value={password} onChange={e => setPassword(e.target.value)} /><br />
//   <button onClick={doLogin}>Login</button>
//   <button onClick={doRegister}>Register</button>
// </form>
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useResource } from "../services/engine";
import Calendar from "./Calendar";
import {Card, Form, Button} from 'tabler-react'

export default ({ jwtData }) => {
  const [newName, setNewName] = useState("");
  const {
    create,
    update,
    remove,

    listData,
    singleData,
    setSingleId,
    listRefetch,
  } = useResource(
    "contacts",
    `
        id
        name
        description
        address
        notes {
          type
          done
        }
          `,
    `
        id
        name
        description
        address
        notes {
            content
            date
            done
            type
            created_at
            user {
                login
            }
        }
      `,
    {
      order: {
        name: "asc",
      },
      where: {
        deleted: { _eq: false },
      },
    }
  );

  if (!listData) return null;
  return (
    <div className='flex'>
      <div className='w-3/4'>
          {listData.map((contact) => (
            <div className='w-1/3 inline-block px-2 contactcard'>
              <Link to={"/crm/" + contact.id}>
              <Card
                title={contact.name}
                body={`${contact.description ? contact.description + ', ' : ''}
                niewykonanych zadań: ${contact.notes ? contact.notes
                  .filter((el) => el.type === "task" && !el.done)
                  .length : 0}`}
              />
              </Link>
            </div>
          ))}
      </div>
      <div className='w-1/4 pl-4'>
      <form
          onSubmit={(e) => {
            e.preventDefault();
            create({
              name: newName,
            });
            setNewName('')
          }}
        >
      <Form.Group label="Nowy kontakt">
          <Form.InputGroup>
            <Form.Input
              placeholder="Nazwa kontaktu..."
              value={newName} onChange={(e) => setNewName(e.target.value)}
            />
          </Form.InputGroup>
          <div className='mt-2'>
          <Button type="submit" color='primary'>Create</Button>
          </div>
        </Form.Group>
        </form>
      </div>
    </div>
  );
};

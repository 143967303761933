import React, { useState, useEffect } from "react";
import { useResource } from "../services/engine";
import { Card, Button, Form } from "tabler-react";
import { Link } from "react-router-dom";
import { useObj } from "../services/useObj";
import { BarcodeReader } from "./BarcodeReader";
import styled from "@emotion/styled";
import DriverSummary from "./DriverSummary";
import book from "../icons/bookopen.svg";
import * as R from "ramda";
import dayjs from "dayjs";
import { filterFunction } from "./Zlecenia";
import { gql, useQuery } from "@apollo/client";

const bagGenresList = ["LM", "OB", "BR", "PB", "PRV", "BIAŁY"];

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export default ({ jwtData }) => {
  const [districtFilter, setDistrictFilter] = useState({});
  const [bagtypeFilter, setBagtypeFilter] = useState({});

  const { data: userData } = useQuery(gql`{
    users_by_pk(id: "${jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"]}") {
      points
    }
  }`);

  // const { update: updatePosition, create: createPosition } = useResource(
  //   "positions",
  //   "id",
  //   "id"
  // );
  const [mode, setMode] = useState("base");
  const [activeTask, setActiveTask] = useState(false);
  const { update, listData, listRefetch } = useResource(
    "orders",
    `
    id
    created_at
    priority
    bagGenre
    comment
    status
    delivery
    numbers
    bags
    sumToTake
    sumTaken
    additional
    communication
    driver {
      id
      login
    }
  `,
    `
    id
  `,
    {
      where: {
        status: {
          _eq: "Accepted",
        },
        driver_id: {
          _eq: jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
        },
      },
    }
  );
  console.log(listData);

  useEffect(() => {
    setInterval(() => {
      listRefetch();
    }, 1000 * 60); // one minute
  }, []);

  const [sorttype, setSorttype] = useState("distance");
  const [communication, setCommunication] = useState("");
  const [comment, setComment] = useState("");
  const [currentPosition, setCurrentPosition] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);

  const [sumTaken, setSumTaken] = useState(0);

  const loadLocation = () => {
    console.log(navigator.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          console.log(position);
          setCurrentPosition(position);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert("Musisz mieć geolokalizację!");
    }
  };

  useEffect(() => {
    loadLocation();
  }, []);

  const distance = (lat, lng) => {
    const { latitude, longitude } = currentPosition.coords;
    console.log(lat, lng, latitude, longitude);
    return (
      Math.round(
        100 * getDistanceFromLatLonInKm(lat, lng, latitude, longitude)
      ) / 100
    );
    // return Math.sqrt(
    //   (latitude - lat) * (latitude - lat) +
    //     (longitude - lng) * (longitude - lng)
    // );
  };

  // x.innerHTML = "Latitude: " + position.coords.latitude +
  // "<br>Longitude: " + position.coords.longitude;

  console.log(userData);

  const { data, set, reset } = useObj({});

  const saveCommunication = (order) => {
    update(order.id, {
      communication: `${
        order.communication || ""
      }<b>Kierowca ${dayjs().format(
        "YYYY-MM-DD, HH:mm"
      )}</b>: ${communication}<br />`,
    });
  };

  const finish = (order) => {
    update(order.id, {
      status: "Done",
      finish_date: dayjs(),
      numbers: data,
      sumTaken,
    });

    setSumTaken(0)
    reset()

  };

  if (!listData) return <div>Ładowanie...</div>;
  if (!currentPosition) return <div>Lokalizacja...</div>;

  const builtData = listData
    .map((order) => {
      if (!order.delivery) return false;
      const delivery = order.delivery;
      const [lat, lng] = delivery.location ? delivery.location 
        // .slice(1, -1)
        .split(",")
        .map((el) => parseFloat(el)) : [0, 0];
      const [floatLat, floatLng] = [parseFloat(lat), parseFloat(lng)];
      console.log(lat, lng, floatLat, floatLng, distance(floatLat, floatLng));
      return {
        ...order,
        priority: order.priority
          ? order.priority
          : Math.abs(dayjs().diff(order.created_at, "days")) > 14
          ? 1
          : 0,
        distance: distance(floatLat, floatLng),
        mapsString:
          `https://maps.google.com/?q=` +
          delivery.address +
          ", " +
          delivery.city,
      };
    })
    .filter((el) => el)
    .sort((a, b) => {
      if (a.priority && b.priority && b.priority !== a.priority)
        return b.priority - a.priority;
      if (sorttype === "distance") {
        if (isNaN(a.distance)) return -1;
        if (isNaN(b.distance)) return 1;
        return a.distance - b.distance;
      } else if (sorttype === "date") {
        console.log(a.created_at);
        console.log(b.created_at);
        console.log(
          dayjs(a.created_at)
            .format("YYYY-MM-DD")
            .localeCompare(dayjs(b.created_at).format("YYYY-MM-DD"))
        );
        return dayjs(a.created_at)
          .format("YYYY-MM-DD")
          .localeCompare(dayjs(b.created_at).format("YYYY-MM-DD"));
      }
    });

  const districts = R.uniq(
    builtData
      .map((el) => (el.delivery ? el.delivery.district : false))
      .filter((el) => el)
  );

  const cancel = (order) => {
    update(order.id, {
      status: "Error",
      comment: comment,
    });
    setCancelOrder(false);
    setSumTaken("");
    setComment("");
  };

  const filteredData = builtData
    .filter(filterFunction(bagtypeFilter, ["bagGenre"]))
    .filter(filterFunction(districtFilter, ["delivery", "district"]));

  return (
    <div
      style={{
        paddingTop: 24,
      }}
    >
      

      <div
        style={{
          position: "fixed",
          right: 8,
          top: 8,
          zIndex: 1000,
        }}
      >
        {userData
          ? Object.keys(userData.users_by_pk.points).map((punkt) => (
              <a target="_blank" href={userData.users_by_pk.points[punkt]}>
                <span>{punkt}</span>
              </a>
            ))
          : null}
        <img
          onClick={() => {
            if (mode === "base") setMode("summary");
            else setMode("base");
          }}
          src={book}
        />
      </div>

      {mode === "summary" && (
        <>
          <DriverSummary jwtData={jwtData} />
        </>
      )}

      {mode === "base" && (
        <>
        <b>Sortowanie</b>
      <br />
      <span
        onClick={() => {
          setSorttype("distance");
        }}
        style={{
          fontWeight: sorttype === "distance" ? "bold" : "normal",
        }}
      >
        dystans
      </span>

      <span
        onClick={() => {
          setSorttype("date");
        }}
        style={{
          fontWeight: sorttype === "date" ? "bold" : "normal",
        }}
      >
        data
      </span>
      <br />
      <b>Rodzaje worków</b>
      <br />
      {bagGenresList.map((genre) => (
        <label>
          <input
            type="checkbox"
            checked={bagtypeFilter[genre]}
            onChange={(e) =>
              setBagtypeFilter({
                ...bagtypeFilter,
                [genre]: e.target.checked,
              })
            }
          />
          &nbsp;{genre}&nbsp;&nbsp;&nbsp;
        </label>
      ))}
      <br />
      <b>Dzielnice</b>
      <br />
      {districts.map((district) => (
        <label>
          <input
            type="checkbox"
            checked={districtFilter[district]}
            onChange={(e) =>
              setDistrictFilter({
                ...districtFilter,
                [district]: e.target.checked,
              })
            }
          />
          &nbsp;{district}&nbsp;&nbsp;&nbsp;
        </label>
      ))}
          {filteredData.map((order) => (
            <div
              style={{
                borderLeft:
                  "10px solid " +
                  (order.priority ? "rgba(255, 0, 0, 1)" : "transparent"),
                width: "98%",
                paddingBottom: 8,
              }}
            >
              <Card>
                <div
                  onClick={() => {
                    activeTask === order.id
                      ? setActiveTask(false)
                      : setActiveTask(order.id);
                  }}
                >
                  <Card.Header key={order.id}>
                    <Card.Title>
                      <b>
                        {order.delivery.district &&
                        order.delivery.district !== "brak"
                          ? `[${order.delivery.district}] - `
                          : ""}
                        {order.delivery.address}
                      </b>
                      , {order.distance} km, <b>{order.bags}</b> w,{" "}
                      {dayjs(order.created_at).format("YYYY-MM-DD")}
                    </Card.Title>
                  </Card.Header>
                </div>
                {activeTask === order.id && (
                  <Card.Body>
                    Telefon:{" "}
                    <b>
                      <a href={"tel:" + order.delivery.phone}>
                        {order.delivery.phone}
                      </a>
                    </b>
                    <br />
                    Adres:{" "}
                    <b>
                      {order.delivery.address}, {order.delivery.postcode}{" "}
                      {order.delivery.city}
                    </b>
                    <br />
                    Worek: <b>{order.bagGenre}</b>
                    <br />
                    Kwota do pobrania: <b>{order.sumToTake} zł</b>
                    <br />
                    {order.additional && (
                      <div
                        style={{
                          margin: "8px 0",
                        }}
                      >
                        UWAGI:
                        <b>{order.additional}</b>
                      </div>
                    )}
                    {console.log(order.priority)}
                    {order.priority == 1 && <b>Priorytet</b>}
                    <br />
                    <a href={order.mapsString} target="_blank">
                      <Button color="primary" size="sm">
                        Jedź
                      </Button>
                    </a>
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => {
                        update(order.id, {
                          bags: order.bags + 1,
                        });
                        setTimeout(() => {
                          listRefetch();
                        }, 1500);
                      }}
                    >
                      Dodaj worek
                    </Button>
                    <br />
                    {Array(order.bags)
                      .fill(0)
                      .map((_, ind) => (
                        <>
                          <BarcodeReader onChange={set(ind.toString())}>
                            <Form.Input
                              value={data[ind.toString()]}
                              onChange={set(ind.toString())}
                              placeholder="Numer worka"
                            />
                          </BarcodeReader>
                          <br />
                        </>
                      ))}
                    <>
                      <Form.Input
                        value={sumTaken}
                        onChange={(e) =>
                          setSumTaken(
                            e.target.value
                              .replace(/,/g, ".")
                              .replace(/[^0-9\.]/g, "")
                          )
                        }
                        placeholder="Suma pobrana"
                      />
                    </>
                    {(!order.sumToTake || sumTaken) && (
                      <Button
                        onClick={() => finish(order)}
                        color="primary"
                        size="sm"
                      >
                        Zakończ
                      </Button>
                    )}
                    {cancelOrder !== false && (
                      <div>
                        <Form.Input
                          placeholder="Skomentuj..."
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                    )}
                    <Button
                      onClick={() =>
                        cancelOrder ? cancel(order) : setCancelOrder(order)
                      }
                      color="danger"
                      size="sm"
                    >
                      Anuluj
                    </Button>
                    <hr />
                    <b>Komentarze</b>
                    <div
                      dangerouslySetInnerHTML={{ __html: order.communication }}
                    />
                    <Form.Input
                      value={communication}
                      onChange={(e) => setCommunication(e.target.value)}
                      placeholder="Komunikacja"
                    />
                    <Button
                      onClick={() => saveCommunication(order)}
                      color="primary"
                      size="sm"
                    >
                      save
                    </Button>
                    
                  </Card.Body>
                )}
              </Card>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

import React, { useState } from "react";
import * as R from "ramda";
import { useMutation, useQuery } from "@apollo/client";
import { Table } from "tabler-react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useResource } from "../services/engine";
import { filterFunction, filterSum } from "./Zlecenia";
import EditZlecenie from "./EditZlecenie";

export const KwotaFilter = ({ value, set }) => {
  return (
    <>
      <span
        style={{
          fontWeight: value === false ? "bold" : "normal",
          paddingRight: 4,
        }}
        onClick={() => set(false)}
      >
        Wszystkie
      </span>
      <span
        style={{
          fontWeight: value === "without" ? "bold" : "normal",
          paddingRight: 4,
        }}
        onClick={() => set("without")}
      >
        Bez kwoty
      </span>
      <span
        style={{
          fontWeight: value === "with" ? "bold" : "normal",
          paddingRight: 4,
        }}
        onClick={() => set("with")}
      >
        Z kwotą
      </span>
    </>
  );
};

export default () => {
  const [editedId, setEditedId] = useState(false);

  const [districtFilter, setDistrictFilter] = useState({});
  const [bagtypeFilter, setBagtypeFilter] = useState({});
  const [driverFilter, setDriverFilter] = useState({});

  const [sumToTakeFilter, setSumToTakeFilter] = useState(false);
  const [sumTakenFilter, setSumTakenFilter] = useState(false);
  const [sumBankFilter, setSumBankFilter] = useState(false);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const { listData } = useResource(
    "orders",
    `
        id
        created_at
        priority
        bagGenre
        comment
        status
        delivery
        numbers
        finish_date
        bags
        sumToTake
        sumTaken
        sumBank
        additional
        driver {
          id
          name
        }
    
      `,
    `
        id
      `,
    {
      where: {
        status: {
          _in: ["Done"],
        },
        finish_date: {
          _gt: dayjs().subtract(3, "month").format("YYYY-MM-DD HH:mm"),
          _lt: dayjs().endOf("day").format("YYYY-MM-DD HH:mm"),
        },
      },
      order: {
        created_at: "asc",
      },
    }
  );

  const [deleteOrderMutation] = useMutation(gql`
    mutation DeleteOrder($id: uuid) {
      update_orders(where: { id: { _eq: $id } }, _set: { status: "Deleted" }) {
        affected_rows
      }
    }
  `);

  const deleteOrder = (id) => {
    deleteOrderMutation({
      variables: {
        id,
      },
    });
  };

  if (!listData) return null;

  const districts = R.uniq(
    listData
      .map((el) => (el.delivery ? el.delivery.district : false))
      .filter((el) => el)
  );

  const bagTypes = ["LM", "OB", "BR", "PB", "PRV", "BIAŁY"];

  const drivers = R.uniq(
    listData
      .map((el) => (el.driver ? el.driver.name : false))
      .filter((el) => el)
  );

  const filteredData = listData
    .filter(filterFunction(bagtypeFilter, ["bagGenre"]))
    .filter(filterFunction(driverFilter, ["driver", "name"]))
    .filter(filterFunction(districtFilter, ["delivery", "district"]))
    .filter(filterSum(sumToTakeFilter, "sumToTake"))
    .filter(filterSum(sumTakenFilter, "sumTaken"))
    .filter(filterSum(sumBankFilter, "sumBank"))
    .filter((order) => {
      if (dateFrom === "") return true;

      const date = dayjs(dateFrom);
      if (!date.isValid()) return true;

      const finishDate = dayjs(order.finish_date);
      if (finishDate.isBefore(date)) return false;
      return true;
    })
    .filter((order) => {
      if (dateTo === "") return true;

      const date = dayjs(dateTo);
      if (!date.isValid()) return true;

      const finishDate = dayjs(order.finish_date);
      if (finishDate.isAfter(date)) return false;
      return true;
    });

  return (
    <div>
      {editedId && (
        <EditZlecenie
          close={() => setEditedId(false)}
          data={listData.find((el) => el.id === editedId)}
        />
      )}
      <b>Rodzaje worków</b>
      <br />
      {bagTypes.map((genre) => (
        <label>
          <input
            type="checkbox"
            checked={bagtypeFilter[genre]}
            onChange={(e) =>
              setBagtypeFilter({
                ...bagtypeFilter,
                [genre]: e.target.checked,
              })
            }
          />
          &nbsp;{genre}&nbsp;&nbsp;&nbsp;
        </label>
      ))}
      <br />
      <b>Dzielnice</b>
      <br />
      {districts.map((district) => (
        <label>
          <input
            type="checkbox"
            checked={districtFilter[district]}
            onChange={(e) =>
              setDistrictFilter({
                ...districtFilter,
                [district]: e.target.checked,
              })
            }
          />
          &nbsp;{district}&nbsp;&nbsp;&nbsp;
        </label>
      ))}
      <br />
      <b>Kierowcy</b>
      <br />
      {drivers.map((driver) => (
        <label>
          <input
            type="checkbox"
            checked={driverFilter[driver]}
            onChange={(e) =>
              setDriverFilter({
                ...driverFilter,
                [driver]: e.target.checked,
              })
            }
          />
          &nbsp;{driver}&nbsp;&nbsp;&nbsp;
        </label>
      ))}
      <br />
      <b>Do pobrania</b>
      <br />
      <KwotaFilter value={sumToTakeFilter} set={setSumToTakeFilter} />
      <br />
      <b>Pobrana</b>
      <br />
      <KwotaFilter value={sumTakenFilter} set={setSumTakenFilter} />
      <br />
      <b>Przelew</b>
      <br />
      <KwotaFilter value={sumBankFilter} set={setSumBankFilter} />
      <br />

      <b>Data od (YYYY-MM-DD)</b>
      <br />
      <input
        value={dateFrom}
        onChange={(e) => {
          setDateFrom(e.target.value);
        }}
      />
      <br />
      <b>Data do (YYYY-MM-DD)</b>
      <br />
      <input
        value={dateTo}
        onChange={(e) => {
          setDateTo(e.target.value);
        }}
      />
      <br />

      <Table>
        <Table.Header>
          <Table.ColHeader>Adres</Table.ColHeader>
          <Table.ColHeader>Kierowca</Table.ColHeader>
          <Table.ColHeader>Zlecenie</Table.ColHeader>
          <Table.ColHeader>Akcje</Table.ColHeader>
        </Table.Header>
        <Table.Body>
          {filteredData.map((order) => (
            <Table.Row
              style={{
                borderBottom: "3px solid black",
              }}
            >
              <Table.Col>
                {order.delivery.address}
                <br /> {order.delivery.postcode} {order.delivery.city}
                <br /> {order.delivery.phone}{" "}
              </Table.Col>
              <Table.Col>
                {order.driver && order.driver.name}
                <br /> {order.comment}
              </Table.Col>
              <Table.Col>
                {dayjs(order.finish_date).format("YYYY-MM-DD")}
                <br />
                {order.bags} {order.bagGenre}
                <br />
                {order.sumTaken}/{order.sumToTake}/{order.sumBank}
                <br />
                {order.additional}
                <br />
                {order.numbers ? Object.values(order.numbers).join(", ") : ""}
              </Table.Col>
              <Table.Col>
                <div
                  onClick={() => {
                    setEditedId(order.id);
                  }}
                >
                  Edit
                </div>
                <div
                  onClick={() => {
                    const confirmed = window.confirm("Na pewno?");
                    if (confirmed == true) {
                      deleteOrder(order.id);
                      alert("Usunięto");
                    } else {
                    }
                  }}
                >
                  Delete
                </div>
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

import { useState } from "react";
import { set, lensPath, view } from "ramda";

export const useObj = (start) => {
  const [data, setData] = useState(start);
  console.log(data);
  return {
    data,
    set: (path) => (value) => {
      console.log('[set-path]', path)
      console.log('[set-value]', value)
      console.log('[set-data]', data)
      setData(
        prev => {
          const newVersion = set(
            lensPath(path.split(".")),
            value.target ? value.target.value : value,
            prev
          )
          console.log('[set-version]', newVersion)
          return newVersion
        }
      );
    },
    setDelicate: (path) => (value) => {
      const lens = lensPath(path.split("."));
      console.log(
        set(
          lens,
          {
            ...view(lens, data),
            ...value,
          },
          data
        )
      );
      setData(
        set(
          lens,
          {
            ...view(lens, data),
            ...value,
          },
          data
        )
      );
    },
    setObject: (value) => {
      setData(value);
    },
    reset: () => {
      setData({
        ...start,
      });
    },
    register: (path) => {
      return {
        value: view(lensPath(path), data),
        onChange: (e) =>
          setData(set(lensPath(path), e.target ? e.target.value : e, data)),
      };
    },
    replace: (newData) => {
      console.log(data);
      console.log(newData);
      setData({
        ...data,
        ...newData,
      });
    },
  };
};

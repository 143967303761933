import React from "react";
import { Link, BrowserRouter, Route } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  gql,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import Zlecenia from "./views/Zlecenia";
import { Site, Nav } from "tabler-react";
import logo from "./views/static/logo.png";
import Driver from "./views/Driver";
import styled from "@emotion/styled";
import NewBuyingOrder from "./views/NewBuyingOrder";
import Costs from "./views/Costs";
import dayjs from "dayjs";
import "dayjs/locale/pl";
import OrdersToSend from "./views/OrdersToSend";
import DayList from "./views/DayList";
import CRM from "./views/CRM";
import CRMSingle from "./views/CRMSingle";
import AllZlecenia from "./views/AllZlecenia";
import { Messages } from "./views/Messages";
import { Report } from "./views/Report";
import Calendar from "./views/Calendar";

var jwtDecode = require("jwt-decode");

const Content = styled.div`
  max-width: 1400px;
  margin: 16px auto;
`;

const Redirector = ({ id, setJWT }) => {
  const { data } = useQuery(gql`{
    users_by_pk(id: "${id}") {
      role
    }
  }`);

  if (!data) return null;
  if (data.users_by_pk.role === "driver") {
    if (window.location.pathname !== "/driver")
      window.location.href = "/driver";
    return <></>;
  }
  return (
    <Site.Header
      imageURL={logo}
      navItems={
        <>
          <Nav.Item value={<Link to="/zlecenia">Dyspozytor</Link>} />
          <Nav.Item value={<Link to="/orderstosend">Wysyłka</Link>} />
          <Nav.Item value={<Link to="/crm">CRM</Link>} />
          <Nav.Item value={<Link to="/allzlecenia">3 mies</Link>} />
          <Nav.Item hasSubNav value="Integracje">
            <Nav.Item>
              <Link to="/traper">Traper</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/messages">Wiadomości</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/baselinker">Magazyn</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/analytics">Analityka</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/calendar">Kalendarz</Link>
            </Nav.Item>
          </Nav.Item>
          <Nav.Item hasSubNav value="Lista dnia">
            <a
              target="_blank"
              href={"/daylist/" + dayjs().format("YYYY-MM-DD")}
            >
              <Nav.SubItem value={"dziś"} />
            </a>
            <a
              target="_blank"
              href={
                "/daylist/" + dayjs().subtract(1, "day").format("YYYY-MM-DD")
              }
            >
              <Nav.SubItem value={"wczoraj"} />
            </a>
            <a
              target="_blank"
              href={
                "/daylist/" + dayjs().subtract(2, "day").format("YYYY-MM-DD")
              }
            >
              <Nav.SubItem value={"przedwczoraj"} />
            </a>
          </Nav.Item>
          <Nav.Item value={<Link to="/newbuying">Zamówienia</Link>} />
          {data.users_by_pk.role === "no_cost" || (
            <Nav.Item hasSubNav value="Koszty">
              {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((sub) => (
                <Link
                  to={
                    "/costs" + dayjs().subtract(sub, "month").format("/YYYY/MM")
                  }
                >
                  <Nav.SubItem
                    value={dayjs()
                      .locale("pl")
                      .subtract(sub, "month")
                      .format("MMMM YYYY")}
                  />
                </Link>
              ))}

              <Link to={"/costs" + dayjs().format("/YYYY/MM")}>
                <Nav.SubItem value={dayjs().locale("pl").format("MMMM YYYY")} />
              </Link>
              <Link to={"/costs" + dayjs().add(1, "month").format("/YYYY/MM")}>
                <Nav.SubItem
                  value={dayjs()
                    .locale("pl")
                    .add(1, "month")
                    .format("MMMM YYYY")}
                />
              </Link>
            </Nav.Item>
          )}
          <Nav.Item
            value={
              <a
                href="#"
                onClick={() => {
                  setJWT(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Logout
              </a>
            }
          />
        </>
      }
    />
  );
};

const Traper = () => {
  return (
    <iframe
      src="https://www.trapergps.pl/"
      style={{
        width: "100%",
        height: "85vh",
        border: 0,
      }}
    />
  );
};

const Baselinker = () => {
  return (
    <iframe
      src="https://panel-b.baselinker.com/"
      style={{
        width: "100%",
        height: "85vh",
        border: 0,
      }}
    />
  );
};

const Analytics = () => {
  return (
    <iframe
      src="https://analytics.gruzbag.krzysztofsikorski.pl/"
      style={{
        width: "100%",
        height: "85vh",
        border: 0,
      }}
    />
  );
};

export default ({ JWT, setJWT }) => {
  const client = new ApolloClient({
    uri: "https://hasura.gruzbag.krzysztofsikorski.pl/v1/graphql",

    headers: {
      // "x-hasura-admin-secret": "rEGqHwMVGsco93r2ntVR"
      Authorization: "Bearer " + JWT,
    },
    cache: new InMemoryCache(),
  });

  const jwtData = jwtDecode(JWT);
  console.log(jwtData);
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Route
          component={() => (
            <Redirector
              setJWT={setJWT}
              id={jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"]}
            />
          )}
          path="/"
        />
        <Content>
          <Route component={Zlecenia} path="/zlecenia" />
          <Route component={Analytics} path="/analytics" />
          <Route
            render={() => <Calendar jwtData={jwtData} />}
            path="/calendar"
          />
          <Route render={() => <Driver jwtData={jwtData} />} path="/driver" />
          <Route component={Report} path="/report/:start/:end" />
          <Route component={Traper} path="/traper" />
          <Route component={Baselinker} path="/baselinker" />
          <Route component={NewBuyingOrder} path="/newbuying" />
          <Route component={OrdersToSend} path="/orderstosend" />
          <Route component={Costs} path="/costs/:year/:month" />
          <Route component={DayList} path="/daylist/:day" />
          <Route component={Messages} path="/messages" />
          <Route exact render={() => <CRM jwtData={jwtData} />} path="/crm" />
          <Route component={CRMSingle} path="/crm/:id" />
          <Route component={AllZlecenia} path="/allzlecenia" />
        </Content>
      </BrowserRouter>
    </ApolloProvider>
  );
};

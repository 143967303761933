import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useResource } from "../services/engine";
import dayjs from "dayjs";
import gql from "graphql-tag";

export default ({ jwtData }) => {
  const { update, listData, listRefetch } = useResource(
    "orders",
    `
        id
        created_at
        priority
        bagGenre
        comment
        status
        delivery
        numbers
        bags
        sumToTake
        sumTaken
        additional
        driver {
          id
          login
        }
      `,
    `
        id
      `,
    {
      where: {
        status: {
          _eq: "Done",
        },
        driver_id: {
          _eq: jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
        },
        finish_date: {
          _gt: dayjs().startOf('month').format("YYYY-MM-DD"),
          _lt: dayjs().add(1, "days").format("YYYY-MM-DD"),
        },
      },
    }
  );

  const {
    data: driverspermonth
  } = useQuery(gql`{
    perdriverpermonth {
      date_trunc
      driver_id
      bags
      sum
    }
  }`)

  const { data: commentData } = useQuery(gql`
        query Comment {
            daycomments_by_pk(date: "${dayjs().format(
              "YYYY-MM-DD"
            )}", driverId: "${
    jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
  }") {
                comment
            }
        }
    `);

  const [upsertComment] = useMutation(gql`
        mutation UpsertComment ($comment: String) {
            insert_daycomments(objects: {comment: $comment, date: "${dayjs().format(
              "YYYY-MM-DD"
            )}", driverId: "${
    jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
  }"}, on_conflict: {constraint: daycomments_pkey, update_columns: comment}) {
                affected_rows
            }
        }
    `);

    console.log(driverspermonth)

  const [comment, setComment] = useState("");

  useEffect(() => {
    if (commentData) {
      setComment(
        commentData.daycomments_by_pk
          ? commentData.daycomments_by_pk.comment
          : ""
      );
    }
  }, [commentData]);

  if (!listData) return null;
  return (
    <><br /><br />
      Komentarz do dnia:
      <input
        type="text"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <button
        onClick={() => {
          upsertComment({
            variables: {
              comment,
            },
          });
        }}
      >
        Zapisz
      </button>
      <br />
      <br />
      Dostarczono pod adresy ({listData.length}){" "}
      <ul>
        {listData.map((order) => (
          <li>
            {order.delivery.phone} -
            {order.delivery.address}, {order.delivery.postcode}{" "}
            {order.delivery.city} (
            {order.numbers ? Object.values(order.numbers).join(", ") : ""})
          </li>
        ))}
      </ul>
      <br />
      <br />
      Pobrano: {listData.reduce((agg, el) => agg + parseInt(el.sumTaken), 0)}
      <br /> <br />
      Zestawienia miesięczne:
      {driverspermonth && <div>
        {driverspermonth.perdriverpermonth.sort((a, b) => a.date_trunc - b.date_trunc).map(el => <div>
          <span>{dayjs(el.date_trunc).format('MMMM YYYY')} - zebranych worków: {el.bags}, zebrana kwota: {el.sum}</span>
        </div>)}
      </div>}
    </>
  );
};

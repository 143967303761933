import React, {useState, useEffect} from "react";
import QrReader from 'react-qr-reader'
import barcode from './static/barcode.svg'
import times from './static/times.svg'

export const BarcodeReader = ({
    onChange, children
}) => {
    const [openValue, setOpen] = useState(false)

  const handleError = err => {
    console.log(err)
  }

  const handleScan = value => {
    if (value) {
    onChange(value)
    setOpen(false)
    }
  }
  return (
      <>
      {
          openValue && <div style={{
            position: 'fixed',
            background: 'black',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
          }}>
            <div style={{
              position: 'fixed',
              top: 16,
              right: 16,
              zIndex: 4000,
              background: 'black',
              width: 16,
              cursor: 'pointer'
            }}
            onClick={() => {
              setOpen(false)
            }}
            >
              <img style={{
                width: 32,
                filter: 'invert(100%)'
              }} src={times} />
            </div>
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ 
                  width: '100vw',
                  height: '100vh' }}
            />
          </div>
      }
    <div style={{
        position: 'relative'
    }}>
        <img style={{
            position: 'absolute',
            right: 20,
            top: '50%',
            height: 20,
            marginTop: '-10px'
        }}
        src={barcode}
        onClick={() => setOpen(true)}
        />
        {children}
    </div>
    </>
  );
};
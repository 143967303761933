import React, { useEffect, useState } from 'react'
import { Form } from 'tabler-react'
import { gql, useMutation } from '@apollo/client';
import * as R from 'ramda'

export default ({data, close}) => {
    const [
        obj, setObj
    ] = useState(false)
    useEffect(() => {
        if (obj) return () => {}
        setObj(data)
    }, [data])

    const [update] = useMutation(gql`
    mutation Update ($id: uuid, $sumBank: numeric, $sumToTake: numeric, $sumTaken: numeric, $bags: Int, $numbers: jsonb){
        update_orders(where: {id: {_eq: $id}}, _set: {sumBank: $sumBank, sumToTake: $sumToTake, sumTaken: $sumTaken, bags: $bags, numbers: $numbers}) {
          affected_rows
        }
      }
    `, {
        onCompleted: () => {
            close()
        }
    });

    const setPath = path => value => setObj(prev => R.set(
        R.lensPath(path),
        value,
        prev
    ))

    console.log('[newNumbers]', obj.bags)
    if (!obj) return null

    return <div style={{
        position: 'fixed',
        top: '20%',
        left: '50%',
        marginLeft: '-200px',
        width: '400px',
        background: 'white',
        padding: 20
    }}>
<Form.Group label="Worki">
              <Form.InputGroup>
            {
                Array.from({length: obj.bags}).map((bag, ind) => <div>
                <Form.Input
                  placeholder="Wpisz kod worka"
                  value={obj.numbers[ind]}
                  onChange={e => {
                      setPath([
                          'numbers', new String(ind).toString()
                      ])(e.target.value)
                  }}
                />
                <span onClick={() => {
                    const newNumbers = R.zipObj(
                        Array.from({
                            length: obj.bags - 1
                        }).map((el, ind) => new String(ind).toString()),
                        Object.keys(obj.numbers).filter(key => key != ind).map(key => obj.numbers[key])
                    )

                    // console.log('[newNumbers]', Array.from({
                    //         length: obj.bags - 1
                    //     }).map((el, ind) => new String(ind).toString()))
                    // console.log('[newNumbers]', Object.keys(obj.numbers).filter(key => key != ind).map(key => obj.numbers[key]))

                    // console.log('[newNumbers]', newNumbers)
                    setPath(['numbers'])(newNumbers)
                    setPath(['bags'])(obj.bags - 1)
                }}>usuń</span>
                </div>)
            }
            </Form.InputGroup>
            </Form.Group>
            <div onClick={() => {
                setPath(['bags'])(obj.bags + 1)
            }}>Dodaj worek</div>

            <hr />

            <Form.Group label="Suma do pobrania">
              <Form.InputGroup>
                <Form.Input
                  placeholder="Wpisz sumę do pobrania..."
                  value={obj.sumToTake}
                  onChange={e => {
                    setPath(['sumToTake'])(e.target.value)
                  }}
                />
              </Form.InputGroup>
            </Form.Group>
            <Form.Group label="Suma pobrana">
              <Form.InputGroup>
                <Form.Input
                  placeholder="Wpisz sumę pobraną..."
                  value={obj.sumTaken}
                  onChange={e => {
                    setPath(['sumTaken'])(e.target.value)
                  }}
                />
              </Form.InputGroup>
            </Form.Group>
            <Form.Group label="Suma przelewu">
              <Form.InputGroup>
                <Form.Input
                  placeholder="Wpisz sumę przelewu..."
                  value={obj.sumBank}
                  onChange={e => {
                    setPath(['sumBank'])(e.target.value)
                  }}
                />
              </Form.InputGroup>
            </Form.Group>

            <hr />

            <div onClick={() => {
                update({
                    variables: {
                        id: obj.id,
                        sumTaken: parseFloat(obj.sumTaken || 0),
                        sumBank: parseFloat(obj.sumBank || 0),
                        sumToTake: parseFloat(obj.sumToTake || 0),
                        numbers: obj.numbers,
                        bags: obj.bags
                    },
                    
                })
            }}>Zapisz</div>
            <div onClick={close}>Zamknij</div>
    </div>
}
import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import ReactDOM from "react-dom";
import * as R from "ramda";
import dayjs from "dayjs";
import "dayjs/locale/pl";
import { Button } from "tabler-react";

import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";

export default () => {
  const [costsSum, setCostsSum] = useState(0);
  const [earningsSum, setEarningsSum] = useState(0);

  const [costsApi, setCostsApi] = useState(null);
  const [earningApi, setEarningApi] = useState(null);
  const { year, month } = useParams();

  const { loading, error, data: dataQuery } = useQuery(
    gql`
        query GetCosts {
            costs_by_pk(month: "${month}", year: "${year}") {
            data
            }
        }
    `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [saveCost] = useMutation(gql`
        mutation UpsertCost($data: jsonb) {
            insert_costs(objects: {data: $data, month: "${month}", year: "${year}"}, on_conflict: {constraint: costs_pkey, update_columns: data}) {
                affected_rows
            }
        }
    `);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!dataQuery) return;
    setData(dataQuery.costs_by_pk ? R.clone(dataQuery.costs_by_pk.data) : []);
  }, [dataQuery]);

  console.log(data);

  const state = {
    modules: AllCommunityModules,
    columnDefs: [
      { headerName: "Dzień", field: "day", filter: "agNumberColumnFilter" },
      {
        headerName: "Wartość netto",
        field: "netto",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "VAT",
        field: "vat",
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["0%", "8%", "23%"],
        },
        filter: "agTextColumnFilter",
      },
      { headerName: "Opis", field: "desc", filter: "agTextColumnFilter" },
      {
        headerName: "Typ",
        field: "type",
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: [
            "Samochody",
            "Biuro",
            "Paliwo",
            "Utylizacja",
            "Materiały",
            "Pracownicy",
          ],
        },
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Forma",
        field: "form",
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["faktura", "brak", "paragon fisk."],
        },
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Przelew?",
        field: "przelew",
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Przelew", "Gotówka"],
        },
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Region",
        field: "region",
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Warszawa", "Łódź", "Kraków", "Lublin", "Wrocław"],
        },
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Numer faktury",
        field: "invoice",
        filter: "agTextColumnFilter",
      },
    ],
    rowData: data,
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
    },
    getRowNodeId: function (data) {
      return data.id;
    },
  };

  useEffect(() => {
    console.log(costsApi);
    console.log(earningApi);
    if (!costsApi || !earningApi) return;

    setInterval(() => {
      let costsSum = 0;
      costsApi.forEachNodeAfterFilter((node, index) => {
        costsSum += parseFloat(node.data.netto) || 0;
      });
      setCostsSum(costsSum);

      let earningSum = 0;
      earningApi.forEachNodeAfterFilter((node, index) => {
        earningSum += parseFloat(node.data.netto) || 0;
      });
      setEarningsSum(earningSum);
    }, 1000);
  }, [costsApi, earningApi]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (!data) return "Loading...";
  return (
    <>
      <div
        style={{
          fontSize: 24,
          textAlign: "center",
          marginBottom: 16,
        }}
      >
        {dayjs(`${year}-${month}-01`).locale("pl").format("MMMM YYYY")}
      </div>
      <div
        style={{
          width: "100%",
          // float: "left",
        }}
      >
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%", marginBottom: 10 }}
        >
          <AgGridReact
            onGridReady={(params) => setCostsApi(params.api)}
            isExternalFilterPresent={() => true}
            doesExternalFilterPass={(node) => node.data.genre !== "earning"}
            modules={state.modules}
            rowData={state.rowData}
            columnDefs={state.columnDefs}
            defaultColDef={state.defaultColDef}
            animateRows={true}
            getRowNodeId={state.getRowNodeId}
            onCellValueChanged={() => {
              saveCost({
                variables: {
                  data: data.filter((row) =>
                    Object.values(row).some((el) => el)
                  ),
                },
              });
            }}
          ></AgGridReact>
        </div>
        <div className="p-4">Suma kosztów (po filtrach): {costsSum}</div>
        <Button
          block
          color="primary"
          onClick={() => {
            setData(
              data.concat([
                {
                  genre: "cost",
                },
              ])
            );
          }}
        >
          Nowy koszt
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          // float: "right",
        }}
      >
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%", marginBottom: 10 }}
        >
          <AgGridReact
            onGridReady={(params) => setEarningApi(params.api)}
            isExternalFilterPresent={() => true}
            doesExternalFilterPass={(node) => node.data.genre === "earning"}
            modules={state.modules}
            rowData={state.rowData}
            columnDefs={state.columnDefs}
            defaultColDef={state.defaultColDef}
            animateRows={true}
            getRowNodeId={state.getRowNodeId}
            onCellValueChanged={() => {
              saveCost({
                variables: {
                  data: data.filter((row) =>
                    Object.values(row).some((el) => el)
                  ),
                },
              });
            }}
          ></AgGridReact>
        </div>

        <div className="p-4">Suma przychodów (po filtrach): {earningsSum}</div>
        <Button
          block
          color="primary"
          onClick={() => {
            setData(
              data.concat([
                {
                  genre: "earning",
                },
              ])
            );
          }}
        >
          Nowy przychód
        </Button>
      </div>
    </>
  );
};

import React from 'react';
import {LoginPage} from 'tabler-react'
import styled from '@emotion/styled'
import { useLocalStorage } from './services/useLocalStorage';
import Routes from './Routes';
import Login from './Login';
import "tabler-react/dist/Tabler.css";

const Global = styled.div`

`

function App() {

  const [JWT, setJWT] = useLocalStorage('JWT', false);

  return <Global>
    {!JWT && <Login set={setJWT} />}
    {JWT && <Routes JWT={JWT} setJWT={setJWT} />}
  </Global>
}

export default App;

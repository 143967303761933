import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import AlgoliaPlaces from "algolia-places-react";
import * as R from "ramda";
import { Card, Header } from "tabler-react";
import { useLazyQuery, gql } from "@apollo/client";
import ky from "ky";
import { useObj } from "../services/useObj";
import { useResource } from "../services/engine";
import { Form, Button } from "tabler-react";
import dayjs from "dayjs";
import { microAddress } from "../services/consts";

const CenterForm = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

export default ({ location }) => {
  const initialAddress =
    location.state && location.state.address ? location.state.address : {};
  const { data, set, reset, replace } = useObj({
    email: "",
    phone: "",

    delivery_fullname: "",
    delivery_company: "",
    delivery_address: "",
    delivery_postcode: "",
    delivery_city: "",

    invoice_fullname: "",
    invoice_company: "",
    invoice_nip: "",
    invoice_address: "",
    invoice_postcode: "",
    invoice_city: "",

    ownDelivery: false,
    product: "",
    bags: 1,
    invoiceNeeded: true,
    ...initialAddress,
  });

  const send = (e) => {
    e.preventDefault();

    ky.post(microAddress + "/createOrder", {
      json: {
        email: data.email,
        phone: data.phone,

        delivery_fullname: data.delivery_fullname,
        delivery_company: data.delivery_company,
        delivery_address: data.delivery_address,
        delivery_postcode: data.delivery_postcode,
        delivery_city: data.delivery_city,

        invoice_fullname: data.invoice_fullname,
        invoice_company: data.invoice_company,
        invoice_nip: data.invoice_nip,
        invoice_address: data.invoice_address,
        invoice_postcode: data.invoice_postcode,
        invoice_city: data.invoice_city,

        products: [
          {
            storage: "db",
            storage_id: "0",
            ...getProduct(data.product),
            quantity: data.bags,
          },
        ],

        delivery_method: data.ownDelivery ? "Własna" : "Kurier GLS",
        delivery_price: 0,

        want_invoice: data.invoiceNeeded ? 1 : 0,
      },
    })
      .text()
      .then(() => {
        alert("Dodano!");
      });

    reset();
  };

  const [search, setSearch] = useState("");
  const [getSearch, { data: results }] = useLazyQuery(gql`
    query Search($query: String) {
      baselinkerLog(where: { data: { _ilike: $query } }) {
        data
      }
    }
  `);
  useEffect(() => {
    if (search !== "")
      getSearch({
        variables: {
          query: "%" + search + "%",
        },
      });
  }, [search]);

  const [products, setProducts] = useState(false);
  const getProduct = (id) => products.find((el) => el.product_id === id);
  const getProducts = () => {
    ky.get(microAddress + "/products")
      .json()
      .then((data) => {
        setProducts(data.products);
      });
  };
  useEffect(getProducts, []);

  if (!products) return null;

  console.log(data);

  return (
    <CenterForm>
      {/* <Header.H3
        style={{
          marginTop: 16,
        }}
      >
        Szukaj
      </Header.H3>
      <Form.InputGroup>
        <Form.Input
          placeholder="Wpisz cokolwiek..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.InputGroup> */}

      {/* {search !== "" && results && (
        <>
          {results.baselinkerLog
            .map((el) => JSON.parse(el.data))
            .map((result) => (
              <div
                onClick={() => {
                  console.log("clicked");
                  replace(result);
                  setSearch("");
                }}
              >
                <Card>
                  <Card.Body>
                    Ogólne:
                    <br />
                    {result.email} {result.phone}
                    <br />
                    Dostawa:
                    <br />
                    {result.delivery_fullname} ({result.delivery_company})<br />
                    {result.delivery_address}
                    <br />
                    {result.delivery_postcode} {result.delivery_city}
                    <br />
                    <br />
                    Faktura:
                    <br />
                    {result.invoice_fullname} ({result.invoice_company} -{" "}
                    {result.invoice_nip})<br />
                    {result.invoice_address}
                    <br />
                    {result.invoice_postcode} {result.invoice_city}
                    <br />
                  </Card.Body>
                </Card>
              </div>
            ))}
        </>
      )} */}

      <Form.Group label="Email">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz email..."
            value={data.email}
            onChange={set("email")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Telefon">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz telefon..."
            value={data.phone}
            onChange={set("phone")}
          />
        </Form.InputGroup>
      </Form.Group>

      <div
        style={{
          marginTop: 32,
        }}
      >
        <Header.H3
          style={{
            marginTop: 16,
          }}
        >
          Dostawa
        </Header.H3>
      </div>

      <Form.Group label="Nazwa odbiorcy">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz odbiorcę..."
            value={data.delivery_fullname}
            onChange={set("delivery_fullname")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Nazwa firmy">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz firmę..."
            value={data.delivery_company}
            onChange={set("delivery_company")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Ulica">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz ulicę i numer..."
            value={data.delivery_address}
            onChange={set("delivery_address")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Kod i miasto">
        <Form.InputGroup>
          <Form.Input
            className="postcode-input"
            placeholder="Wpisz kod pocztowy..."
            value={data.delivery_postcode}
            onChange={set("delivery_postcode")}
          />
          <Form.Input
            className="city-input"
            placeholder="Wpisz miasto..."
            value={data.delivery_city}
            onChange={set("delivery_city")}
          />
        </Form.InputGroup>
      </Form.Group>

      <Button
        block
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          replace({
            invoice_fullname: data.delivery_fullname,
            invoice_company: data.delivery_company,
            invoice_address: data.delivery_address,
            invoice_postcode: data.delivery_postcode,
            invoice_city: data.delivery_city,
          });
        }}
      >
        Ustaw jak wyżej
      </Button>

      <div
        style={{
          marginTop: 32,
        }}
      >
        <Header.H3>Faktura</Header.H3>
      </div>

      <Form.Group label="Nazwa odbiorcy">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz odbiorcę..."
            value={data.invoice_fullname}
            onChange={set("invoice_fullname")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Nazwa firmy">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz firmę..."
            value={data.invoice_company}
            onChange={set("invoice_company")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="NIP">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz NIP..."
            value={data.invoice_nip}
            onChange={set("invoice_nip")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Ulica">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz ulicę i numer..."
            value={data.invoice_address}
            onChange={set("invoice_address")}
          />
        </Form.InputGroup>
      </Form.Group>
      <Form.Group label="Kod i miasto">
        <Form.InputGroup>
          <Form.Input
            className="postcode-input"
            placeholder="Wpisz kod pocztowy..."
            value={data.invoice_postcode}
            onChange={set("invoice_postcode")}
          />
          <Form.Input
            className="city-input"
            placeholder="Wpisz miasto..."
            value={data.invoice_city}
            onChange={set("invoice_city")}
          />
        </Form.InputGroup>
      </Form.Group>

      <div
        style={{
          marginTop: 32,
        }}
      >
        <Header.H3>Produkty</Header.H3>
      </div>

      <Form.Group label="Worki">
        <Form.InputGroup>
          <Form.Input
            placeholder="Wpisz worki..."
            value={data.bags}
            onChange={(e) => {
              set("bags")(e.target.value);
            }}
          />
        </Form.InputGroup>
      </Form.Group>

      <Form.Group label="Produkty">
        <Form.SelectGroup onChange={(e) => set("product")(e.target.value)}>
          {products.map((product) => (
            <Form.SelectGroupItem
              checked={data.product === product.product_id}
              label={product.name}
              name="product"
              value={product.product_id}
            />
          ))}
        </Form.SelectGroup>
      </Form.Group>

      <Form.Group label="Własna dostawa">
        <input
          type="checkbox"
          checked={data.ownDelivery}
          onChange={(e) => set("ownDelivery")(e.target.checked)}
        />
      </Form.Group>

      <Form.Group label="Faktura wymagana">
        <input
          type="checkbox"
          checked={data.invoiceNeeded}
          onChange={(e) => set("invoiceNeeded")(e.target.checked)}
        />
      </Form.Group>

      <form onSubmit={send}>
        <Button block color="success">
          Dodaj
        </Button>
      </form>
    </CenterForm>
  );
};

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import AlgoliaPlaces from "algolia-places-react";
import { useObj } from "../services/useObj";
import { useResource } from "../services/engine";
import editImage from "./static/edit-solid.svg";
import checkImage from "./static/check-solid.svg";
import trash from "./static/trash.svg";
import ky from "ky";
import { Form, Button } from "tabler-react";
import dayjs from "dayjs";
import { Table } from "tabler-react";
import * as R from "ramda";
// import LocationPicker from "location-picker";
import { microAddress } from "../services/consts";
import { gql, useQuery } from "@apollo/client";

{
  /* <AlgoliaPlaces
                placeholder="Adres..."
                options={{
                  appId: "plDMHTTCYHK0",
                  apiKey: "83bdc80ed5c28dcb44b5eb25a2da809c",
                  language: "pl",
                  countries: ["pl"],
                  type: "address",
                }}
                onChange={async ({
                  query,
                  rawAnswer,
                  suggestion,
                  suggestionIndex,
                }) => {
                  console.log(
                    "Fired when suggestion selected in the dropdown or hint was validated."
                  );
                  console.log(query);
                  console.log(rawAnswer);
                  console.log(suggestion);

                  // Dirty hack because this component has shouldComponentUpdate on false
                  // and doesnt work with hooks

                  set("delivery.address")(suggestion.name);
                  set("delivery.city")(suggestion.city);
                  set("delivery.postcode")(suggestion.postcode);

                  const driver_id = setDrivers(
                    suggestion.latlng.lat,
                    suggestion.latlng.lng
                  );
                  set("driver_id")(driver_id);
                  const postal = await ky
                    .get(microAddress + "/postal?postal=" + suggestion.postcode)
                    .text();
                  set("delivery.district")(postal);
                }}
              /> */
}

const accessToken =
  "pk.eyJ1Ijoia3J6eXN6dG9maWsiLCJhIjoiY2w1N3BvMG13MGk1czNlcW4wZWtubjMzYiJ9.EVFKg1HCxs1g8r4uMMDVzw";

const algoliaSearch = async (address) => {
  // const body = await fetch("https://places-dsn.algolia.net/1/places/query", {
  //   method: "POST",
  //   headers: {
  //     "X-Algolia-Application-Id": "plDMHTTCYHK0",
  //     "X-Algolia-API-Key": "83bdc80ed5c28dcb44b5eb25a2da809c",
  //     "Content-Type": "application/json; charset=UTF-8",
  //   },
  //   body: JSON.stringify({ query: address }),
  // });
  // const json = await body.json();

  // if (!json.hits[0]) return false;

  // const firstHit = json.hits[0];
  // console.log(firstHit);
  // return firstHit;

  const body = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${accessToken}&country=PL&language=pl&types=address`
  );
  const json = await body.json();

  console.log("[json]", json);
  return json.features;
};

export const filterFunction = (filter, path) => (el) =>
  Object.values(filter).every((e) => e === false) ||
  Object.keys(filter).some(
    (key) =>
      filter[key] &&
      R.view(R.lensPath(path), el) &&
      R.view(R.lensPath(path), el).includes(key)
  );

export const filterSum = (filter, path) => (order) => {
  if (!filter) return true;
  if (filter === "with" && parseFloat(order[path]) > 0) return true;
  if (filter === "without" && (!order[path] || parseFloat(order[path]) === 0))
    return true;
  return false;
};

const CenterForm = styled.div`
  max-width: 480px;
`;

const Columns = styled.div`
  display: flex;
  padding: 0 32px;
`;

const ListColumn = styled.div`
  width: calc(100% - 480px);
  padding-right: 16px;
`;

const nativelySetOnId = (id, value) => {
  const el = document.querySelector("." + id);

  var setter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  if (!el) return;
  setter.call(el, value);

  const event = new Event("input", { bubbles: true });
  el.dispatchEvent(event);
};

const setDrivers = (lat, lng) => {
  console.log(window.kierowcy);
  const drivers = window.kierowcy.users.filter((el) => el.name && el.center);
  const closestDriver = drivers.reduce(
    (currentMin, driver) => {
      const [driverLat, driverLng] = driver.center
        .replace(/[^0-9.,]/g, "")
        .split(",");
      const latDiff = Math.abs(driverLat - lat);
      const lngDiff = Math.abs(driverLng - lng);
      const distance = latDiff * latDiff + lngDiff * lngDiff;
      if (currentMin.amount < distance) return currentMin;
      return {
        driver_id: driver.id,
        amount: distance,
      };
    },
    {
      driver_id: "",
      amount: 1000000000,
    }
  );

  return closestDriver.driver_id;
};

const bagGenresList = ["LM", "OB", "BR", "PB", "PRV", "BIAŁY"];

const loadDistrict = async (code) => {
  const postal = await ky.get(microAddress + "/postal?postal=" + code).text();
  nativelySetOnId("district-input", postal);
  return postal;
};

// const getDistrict = async (delivery) => {
//   const { city, district, postcode, address } = delivery;
//   if (city.toLowerCase() !== "warszawa" || district || !postcode) return false;
//   if (postcode === "-") {
//     const search = await algoliaSearch(address + " " + city);
//     if (search.village && search.village[0]) return search.village[0];
//     if (!search.postcode || !search.postcode[0]) return false;
//     return await loadDistrict(search.postcode[0]);
//   }

//   return await loadDistrict(postcode);
// };

const Communication = ({ order, update }) => {
  const [communication, setCommunication] = useState("");
  const saveCommunication = (order) => {
    update(order.id, {
      communication: `${
        order.communication || ""
      }<b>Dyzpozytor ${dayjs().format(
        "YYYY-MM-DD, HH:mm"
      )}</b>: ${communication}<br />`,
    });
  };

  return (
    <>
      <b>Komentarze</b>
      <div dangerouslySetInnerHTML={{ __html: order.communication }} />
      <Form.Input
        value={communication}
        onChange={(e) => setCommunication(e.target.value)}
        placeholder="Komunikacja"
      />
      <Button
        onClick={() => saveCommunication(order)}
        color="primary"
        size="sm"
      >
        save
      </Button>
    </>
  );
};

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const SearchAddresses = ({ set }) => {
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [autocomplete, setAutocomplete] = useState("");
  const debouncedAutocomplete = useDebounce(autocomplete, 800);

  useEffect(() => {
    (async () => {
      if (debouncedAutocomplete === "") setAutocompleteResults([]);
      else {
        const results = await algoliaSearch(debouncedAutocomplete);
        setAutocompleteResults(results);
      }
    })();
  }, [debouncedAutocomplete]);

  return (
    <Form.Group label="Wyszukaj adres">
      <Form.InputGroup
        style={{
          position: "relative",
        }}
      >
        <Form.Input
          type="text"
          value={autocomplete}
          onChange={(e) => setAutocomplete(e.target.value)}
          placeholder="Wyszukaj..."
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 40,
            border: autocompleteResults.length ? "1px solid black" : "",
            background: "white",
            zIndex: 100,
          }}
        >
          {autocompleteResults.map((result) => (
            <div
              style={{
                padding: 4,
              }}
              onClick={async () => {
                const latitude = result.center[1];
                const longtitude = result.center[0];
                // const postcode = result.context.find(el => el.id.match(/postcode/)).text_pl
                // const city = result.context.find(el => el.id.match(/place/)).text_pl
                // const address = `${result.}`

                const [fullmatch, address, postcode, city] =
                  result.place_name_pl.match(
                    /([^,]+), ([0-9]{2}-[0-9]{3}) ([^,]+),/
                  );

                // Dirty hack because this component has shouldComponentUpdate on false
                // and doesnt work with hooks

                set("delivery.address")(address);
                set("delivery.city")(city);
                set("delivery.postcode")(postcode);

                const driver_id = setDrivers(latitude, longtitude);
                set("driver_id")(driver_id);
                const postal = await ky
                  .get(microAddress + "/postal?postal=" + postcode)
                  .text();
                set("delivery.district")(postal);

                setAutocompleteResults([]);
                setAutocomplete("");
              }}
            >
              {result.place_name_pl}
            </div>
          ))}
        </div>
      </Form.InputGroup>
    </Form.Group>
  );
};

export default () => {
  const [id, setId] = useState(false);

  // Created
  // Paid
  // Accepted
  // Done
  // Canceled
  // Error

  const {
    create,
    singleData,
    update,
    listData,
    setSingleId,
    listRefetch,
    remove,
  } = useResource(
    "orders",
    `
    id
    created_at
    priority
    bagGenre
    comment
    status
    delivery
    numbers
    bags
    sumToTake
    sumTaken
    sumBank
    additional
    communication
    driver_id
    driver {
      id
      name
    }

  `,
    `
    id
    created_at
    priority
    bagGenre
    comment
    status
    delivery
    numbers
    bags
    sumToTake
    sumTaken
    sumBank
    driver_id
    communication
    additional
  `,
    {
      singleId: id,
      where: {
        status: {
          _in: ["Accepted", "Error"],
        },
      },
      order: {
        created_at: "asc",
      },
    }
  );

  const { data: drivers } = useQuery(gql`
    {
      users {
        center
        id
        name
      }
    }
  `);

  useEffect(() => {
    if (drivers) {
      window.kierowcy = drivers;
    }
  }, [drivers]);

  const [districtFilter, setDistrictFilter] = useState({});
  const [bagtypeFilter, setBagtypeFilter] = useState({});
  const [driverFilter, setDriverFilter] = useState({});

  const getLocation = (lat, lng) => lat + "," + lng;

  // const [showMap, setShowMap] = useState(true);

  const { data, set, reset, replace } = useObj({
    bagGenre: "",
    delivery: {
      phone: "",
      address: "",
      postcode: "",
      city: "",
      district: "brak",
      location: false,
    },
    driver_id: "",
    numbers: [],
    bags: 1,
    sumToTake: 0,
    sumBank: 0,
    additional: "",
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     var locationPicker = new LocationPicker(
  //       "map",
  //       {
  //         lat: 52.229676,
  //         lng: 21.011464,
  //       },
  //       {
  //         zoom: 15, // You can set any google map options here, zoom defaults to 15
  //       }
  //     );

  //     setInterval(() => {
  //       const { lat, lng } = locationPicker.getMarkerPosition();
  //       nativelySetOnId("location-input", getLocation(lat, lng));
  //     }, 500);
  //   }, 500);

  //   return () => {};
  // }, []);

  useEffect(() => {
    if (singleData) {
      replace(singleData);
    }
  }, [singleData]);

  const send = (e) => {
    e.preventDefault();

    if (!id) {
      create(
        {
          ...data,
          status: "Accepted",
        },
        {
          refetchQueries: [],
        }
      );
      reset();
      // setShowMap(true);

      setTimeout(() => {
        listRefetch();
      }, 1500);
    } else {
      update(
        data.id,
        R.pick(
          [
            "bagGenre",
            "delivery",
            "bags",
            "additional",
            "sumToTake",
            "driver_id",
            "sumBank",
          ],
          data
        )
      );
      setTimeout(() => {
        listRefetch();
      }, 500);
    }
  };

  const timeToColor = (time, priority) => {
    if (priority) return "rgba(0, 0, 255, 0.3)";
    const day = dayjs().diff(dayjs(time), "day", true);
    if (day < 3) return "rgba(0, 255, 0, 0.3)";
    if (day < 6) return "rgba(0, 255, 255, 0.3)";
    return "rgba(255, 0, 0, 0.3)";
  };

  if (!listData) return <div>Ładowanie...</div>;

  const sortedData = listData
    .map((order) => ({
      ...order,
      priority: order.priority
        ? order.priority
        : Math.abs(dayjs().diff(order.created_at, "days")) > 14
        ? 0.5
        : 0,
    }))
    .filter((el) => el.status === "Error")
    .concat(listData.filter((el) => el.status === "Accepted"));

  const districts = R.uniq(
    sortedData
      .map((el) => (el.delivery ? el.delivery.district : false))
      .filter((el) => el)
  );

  const filteredData = sortedData
    .filter(filterFunction(bagtypeFilter, ["bagGenre"]))
    .filter(filterFunction(districtFilter, ["delivery", "district"]))
    .filter(filterFunction(driverFilter, ["driver", "name"]));

  console.log(data);

  if (!drivers) return null;
  return (
    <div
      style={{
        width: "100vw",
        position: "absolute",
        left: 0,
      }}
    >
      {/* <button
        onClick={async () => {
          for (const order of filteredData) {
            if (!order.delivery.district) {
              update(order.id, {
                delivery: {
                  ...order.delivery,
                  district: "brak",
                },
              });
              await new Promise((r) => setTimeout(r, 500));
              continue;
            }

            const district = await getDistrict(order.delivery);
            if (!district) continue;

            console.log({
              ...order.delivery,
              district,
            });
            update(order.id, {
              delivery: {
                ...order.delivery,
                district,
              },
            });
            await new Promise((r) => setTimeout(r, 500));
          }
        }}
      >
        asdqwe
      </button> */}
      <Columns>
        <ListColumn>
          <b>Rodzaje worków</b>
          <br />
          {bagGenresList.map((genre) => (
            <label>
              <input
                type="checkbox"
                checked={bagtypeFilter[genre]}
                onChange={(e) =>
                  setBagtypeFilter({
                    ...bagtypeFilter,
                    [genre]: e.target.checked,
                  })
                }
              />
              &nbsp;{genre}&nbsp;&nbsp;&nbsp;
            </label>
          ))}
          <br />
          <b>Dzielnice</b>
          <br />
          {districts.map((district) => (
            <label>
              <input
                type="checkbox"
                checked={districtFilter[district]}
                onChange={(e) =>
                  setDistrictFilter({
                    ...districtFilter,
                    [district]: e.target.checked,
                  })
                }
              />
              &nbsp;{district}&nbsp;&nbsp;&nbsp;
            </label>
          ))}
          <br />
          <b>Kierowcy</b>
          <br />
          {drivers.users
            .map((driver) => driver.name)
            .filter((el) => el)
            .map((driver) => (
              <label>
                <input
                  type="checkbox"
                  checked={driverFilter[driver]}
                  onChange={(e) =>
                    setDriverFilter({
                      ...driverFilter,
                      [driver]: e.target.checked,
                    })
                  }
                />
                &nbsp;{driver}&nbsp;&nbsp;&nbsp;
              </label>
            ))}
          <Table>
            <Table.Header>
              <Table.ColHeader>Adres</Table.ColHeader>
              <Table.ColHeader>Kierowca</Table.ColHeader>
              <Table.ColHeader>Zlecenie</Table.ColHeader>
              <Table.ColHeader>Priorytet</Table.ColHeader>
              <Table.ColHeader>Akcje</Table.ColHeader>
            </Table.Header>
            <Table.Body>
              {filteredData.map((order) => (
                <Table.Row
                  key={order.id}
                  style={{
                    background:
                      order.status === "Error"
                        ? "red"
                        : timeToColor(order.created_at, order.priority),
                    borderBottom: "2px solid rgba(0,0,0,0.4)",
                  }}
                >
                  <Table.Col>
                    {order.delivery.address}
                    <br /> {order.delivery.postcode} {order.delivery.city}{" "}
                    {order.delivery.district !== "brak"
                      ? order.delivery.district
                      : ""}
                    <br /> {order.delivery.phone}{" "}
                  </Table.Col>
                  <Table.Col>
                    {order.driver && order.driver.name}
                    <br /> {order.comment}
                  </Table.Col>
                  <Table.Col>
                    {dayjs(order.created_at).format("YYYY-MM-DD")}
                    <br />
                    <b>{order.bags}</b> {order.bagGenre}
                    <br />
                    {order.sumTaken}/{order.sumToTake}/{order.sumBank}
                    {order.additional && (
                      <div
                        style={{
                          margin: "8px 0",
                        }}
                      >
                        UWAGI:
                        <b>{order.additional}</b>
                      </div>
                    )}
                    <Communication update={update} order={order} />
                  </Table.Col>
                  <Table.Col>
                    <input
                      checked={order.priority}
                      onChange={() => {
                        update(order.id, {
                          priority: order.priority ? 0 : 1,
                        });
                      }}
                      type="checkbox"
                    />
                  </Table.Col>
                  <Table.Col>
                    <img
                      onClick={() => {
                        setSingleId(order.id);
                        setId(order.id);
                      }}
                      src={editImage}
                      style={{
                        width: 16,
                        cursor: "pointer",
                        display: "inline",
                      }}
                    />

                    <img
                      onClick={() => {
                        var r = window.confirm("Na pewno?");

                        if (r == true) {
                          remove(order.id);
                        }
                      }}
                      src={trash}
                      style={{
                        width: 13,
                        marginLeft: 8,
                        cursor: "pointer",
                        display: "inline",
                      }}
                    />
                    {order.status === "Error" && (
                      <img
                        onClick={() => {
                          update(order.id, {
                            status: "Accepted",
                            order_date: dayjs(),
                            created_at: dayjs(),
                          });
                        }}
                        src={checkImage}
                        style={{
                          width: 16,
                          display: "inline",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div
            onClick={() => {
              // TODO
              setId(false);
              reset();
            }}
          >
            dodaj
          </div>
        </ListColumn>
        <CenterForm>
          <form onSubmit={send}>
            <SearchAddresses set={set} />
            <Form.Group label="Ulica">
              <Form.InputGroup>
                <Form.Input
                  className="address-input"
                  placeholder="Wpisz ulicę..."
                  value={data.delivery.address}
                  onChange={set("delivery.address")}
                />
              </Form.InputGroup>
            </Form.Group>
            <Form.Group label="Kod, miasto, dzielnica">
              <Form.InputGroup>
                <Form.Input
                  className="postcode-input"
                  placeholder="Wpisz kod pocztowy..."
                  value={data.delivery.postcode}
                  onChange={set("delivery.postcode")}
                />
                <Form.Input
                  className="city-input"
                  placeholder="Wpisz miasto..."
                  value={data.delivery.city}
                  onChange={set("delivery.city")}
                />
              </Form.InputGroup>

              <Form.InputGroup>
                <Form.Input
                  className="district-input"
                  placeholder="Wpisz dzielnicę..."
                  value={data.delivery.district}
                  onChange={set("delivery.district")}
                />
              </Form.InputGroup>
              <div
                style={{
                  marginTop: 4,
                }}
              >
                <Button
                  onClick={() => {
                    loadDistrict(data.delivery.postcode);
                  }}
                  color="primary"
                >
                  Pobranie dzielnicy
                </Button>
              </div>
              {/* <div
                id="map"
                style={{
                  display: showMap && !id ? "block" : "none",
                }}
              ></div> */}
            </Form.Group>
            <Form.Group label="Kierowca">
              <Form.SelectGroup>
                <Form.Select
                  className="driver-select"
                  value={data.driver_id}
                  onChange={set("driver_id")}
                >
                  <option value="">Nie wybrano</option>
                  {drivers.users
                    .filter((driver) => driver.name)
                    .map((driver) => (
                      <option value={driver.id}>{driver.name}</option>
                    ))}
                </Form.Select>
              </Form.SelectGroup>
            </Form.Group>
            <Form.Group label="Telefon">
              <Form.InputGroup>
                <Form.Input
                  placeholder="Wpisz telefon..."
                  value={data.delivery.phone}
                  onChange={set("delivery.phone")}
                />
              </Form.InputGroup>
            </Form.Group>
            <Form.Group label="Worki">
              <Form.SelectGroup
                onChange={(e) => {
                  set("bags")(e.target.value);
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                  <Form.SelectGroupItem
                    checked={data.bags == number}
                    label={number}
                    name="bags"
                    value={number}
                  />
                ))}
              </Form.SelectGroup>
            </Form.Group>
            <Form.Group label="Worki">
              <Form.InputGroup>
                <Form.Input
                  placeholder="Wpisz worki..."
                  value={data.bags}
                  onChange={set("bags")}
                />
              </Form.InputGroup>
            </Form.Group>

            <Form.Group label="Rodzaj worków">
              <Form.InputGroup>
                {bagGenresList.map((genre) => {
                  return (
                    <label
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <input
                        style={{
                          marginRight: 4,
                        }}
                        checked={data.bagGenre.split(",").includes(genre)}
                        onChange={(e) => {
                          const current = data.bagGenre
                            ? data.bagGenre.split(",")
                            : [];
                          console.log(current);
                          if (e.target.checked) {
                            set("bagGenre")(current.concat([genre]).join(","));
                          } else {
                            set("bagGenre")(
                              current.filter((el) => el !== genre).join(",")
                            );
                          }
                        }}
                        type="checkbox"
                      />
                      {genre}
                    </label>
                  );
                })}
              </Form.InputGroup>
            </Form.Group>

            <Form.Group label="Uwagi">
              <Form.Input
                placeholder="Uwagi..."
                value={data.additional}
                onChange={(e) => set("additional")(e.target.value)}
              />
            </Form.Group>

            <Form.Group label="Kwota przelew">
              <Form.Input
                placeholder="Kwota przelew..."
                value={data.sumBank}
                onChange={(e) =>
                  set("sumBank")(
                    parseFloat(
                      e.target.value.replace(/,/g, ".").replace(/[^0-9\.]/g, "")
                    )
                  )
                }
              />
            </Form.Group>

            <Form.Group label="Kwota do pobrania">
              <Form.Input
                placeholder="Kwota do pobrania..."
                value={data.sumToTake}
                onChange={(e) =>
                  set("sumToTake")(
                    parseFloat(
                      e.target.value.replace(/,/g, ".").replace(/[^0-9\.]/g, "")
                    )
                  )
                }
              />
              <div
                style={{
                  marginTop: 4,
                }}
              >
                <Button
                  onClick={() => {
                    const variants = [0, 150, 270, 350, 560];
                    const bagsNumber = parseInt(data.bags);
                    set("sumToTake")(variants[bagsNumber]);
                  }}
                  color="primary"
                >
                  Autopobranie
                </Button>
              </div>
            </Form.Group>

            {data.delivery.address && (
              <Button block color="success">
                {id ? "Edytuj" : "Dodaj"}
              </Button>
            )}
          </form>
        </CenterForm>
      </Columns>
    </div>
  );
};

import { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const QUERIES = (name, queryList, querySingle) => {
  const LIST = gql`
    query ${name}List($where: ${name}_bool_exp, $order: [${name}_order_by!], $limit: Int, $offset: Int) {
      ${name}(where: $where, order_by: $order, limit: $limit, offset: $offset) {
        ${queryList}
      }
    }
  `;

  const SINGLE = gql`
    query ${name}Single ($id: uuid!) {
      ${name}_by_pk(id: $id) {
        ${querySingle}
      }
    }
  `;

  const CREATE = gql`
    mutation Insert${name} ($objects: [${name}_insert_input!]!) {
      insert_${name}(objects: $objects) {
        affected_rows
      }
    }
  `;

  const UPDATE = gql`
    mutation Update${name} ($id: uuid, $input: ${name}_set_input) {
      update_${name}(_set: $input, where: {id: {_eq: $id}}) {
        affected_rows
      }
    }
  `;

  const DELETE = gql`
    mutation Delete${name} ($id: uuid) {
      delete_${name}(where: {id: {_eq: $id}}) {
        affected_rows
      }
    }
  `;

  return {
    LIST,
    SINGLE,
    CREATE,
    UPDATE,
    DELETE,
  };
};

export const useResource = (name, queryList, querySingle, options = {}) => {
  const [singleId, setSingleId] = useState(options.singleId || false);

  const [where, setWhere] = useState(options.where || {});
  const [order, setOrder] = useState(options.order || []);
  const [limit, setLimit] = useState(500000);
  const [offset, setOffset] = useState(0);

  const Queries = QUERIES(name, queryList, querySingle);

  const {
    loading: listLoading,
    error: listError,
    data: listData,
    refetch: listRefetch,
  } = useQuery(Queries.LIST, {
    variables: {
      where,
      order,
      limit,
      offset,
    },
    fetchPolicy: "no-cache",
  });
  const [
    singleMode,
    { loading: singleLoading, error: singleError, data: singleData },
  ] = useLazyQuery(Queries.SINGLE, {
    variables: {
      id: singleId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (singleId) {
      singleMode();
    }
  }, [singleId]);

  const [createMutation] = useMutation(Queries.CREATE, {
    refetchQueries: [name + "List"],
  });

  const [updateMutation] = useMutation(Queries.UPDATE, {
    refetchQueries: [name + "List", name + "Single"],
  });

  const [removeMutation] = useMutation(Queries.DELETE, {
    refetchQueries: [name + "List"],
  });

  const create = (input, options) =>
    createMutation({
      variables: {
        objects: [input],
      },
      ...options,
    });

  const update = (id, input) =>
    updateMutation({
      variables: {
        id,
        input,
      },
    });

  const remove = (id) =>
    removeMutation({
      variables: {
        id,
      },
    });

  return {
    singleMode,
    setSingleId,
    setWhere,
    setOrder,
    setLimit,
    setOffset,
    create,
    update,
    remove,
    singleData: singleData ? singleData[name + "_by_pk"] : false,
    singleError,
    singleLoading,
    singleRefetch: singleMode,
    listData: listData ? listData[name] : false,
    listError,
    listLoading,
    listRefetch,
    setSingleId,
  };
};

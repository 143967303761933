import styled from "@emotion/styled";
import {Form, Button} from 'tabler-react'
import React, { useState } from "react";
import dayjs from 'dayjs'
import { useParams, Link } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useResource } from "../services/engine";
import { pick } from "ramda";
import { useObj } from "../services/useObj";

const CleanInput = styled.input`
  background: transparent;
  border: 0;
`;

const addIndexes = (note, ind) => ({
  ...note,
  ind,
});

const useDefaultedState = (def) => {
  const [st, setSt] = useState(false);
  return {
    value: st || def,
    onChange: (e) => setSt(e.target ? e.target.value : e),
  };
};

export default () => {
  const { id } = useParams();
  const resource = useResource(
    "contacts",
    `
        id
      `,
    `
        id
        name
        description
        address
        notes(order_by: {created_at: desc}) {
            content
            type
            id
            date
            done
            created_at
            stringDate
            user {
                login
            }
        }
      `,
    {
      singleId: id,
      order: {
        name: "asc",
      },
    }
  );

  if (!resource.singleData) return null;
  return <ContactCard {...resource} id={id} />;
};

const ContactCard = ({ singleData, update, remove, id, singleRefetch }) => {
  const {
    create: createNote,
    update: updateNote,
    remove: removeNote,
  } = useResource("notes", "id", "id");

  const { register, data, replace, setDelicate } = useObj(singleData);
  const toRefetch = () => {
    setTimeout(() => {
      singleRefetch();
    }, 500);
  };
  return (
    <div className='relative'>
      <div className='absolute top-0 right-0'>
      <Link to={"/crm"}>
        <Button size="sm" color='warning' className='mr-2'>Do listy</Button>
        
      </Link>
      <Link
        to={{
          pathname: "/newbuying",
          state: {
            address: data.address,
          },
        }}
      >
        <Button size="sm" color='primary' className='mr-2'>Zamów</Button>
      </Link>
      <Button
      size="sm" color='danger'
            onClick={() => {
              update(id, {
                deleted: true,
              });
              setTimeout(() => {
                window.location = "/crm";
              }, 1000);
            }}
          >
            Delete
          </Button>
      </div>
      <h1>{data.name}</h1>
      
          
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            width: '100%'
          }}
        >
          <h3>Dane kontaktu</h3>
          <Form.Group label="Opis kontaktu">
          <Form.InputGroup>
            <Form.Textarea
              placeholder="Opis..."
              {...register(["description"])}
            />
          </Form.InputGroup>
        </Form.Group>
        <Form.Group label="Dane kontaktowe">
          <Form.InputGroup>
            <Form.Input
              placeholder="Adres email..."
              {...register(["address", "email"])}
            />
            <Form.Input
              placeholder="Telefon..."
              {...register(["address", "phone"])}
            />
          </Form.InputGroup>
        </Form.Group>
        <Form.Group label="Faktura">
          <Form.InputGroup>
            <Form.Input
              className='full-width'
              placeholder="NIP..."
              {...register(["address", "invoice_nip"])}
            />
            <Form.Input
              placeholder="Imię i nazwisko..."
              {...register(["address", "invoice_fullname"])}
            />
            <Form.Input
              placeholder="Nazwa firmy..."
              {...register(["address", "invoice_company"])}
            />
            <Form.Input
              className='full-width'
              placeholder="Adres..."
              {...register(["address", "invoice_address"])}
            />
            <Form.Input
              placeholder="Kod pocztowy..."
              {...register(["address", "invoice_postcode"])}
            />
            <Form.Input
              placeholder="Miasto..."
              {...register(["address", "invoice_city"])}
            />
          </Form.InputGroup>
        </Form.Group>

        <Form.Group label="Dostawa">
          <div style={{
            marginBottom: 8
          }}>
        <Button
        color='primary'
            onClick={() => {
              setDelicate("address")({
                delivery_fullname: data.address.invoice_fullname,
                delivery_company: data.address.invoice_company,
                delivery_address: data.address.invoice_address,
                delivery_postcode: data.address.invoice_postcode,
                delivery_city: data.address.invoice_city,
              });
            }}
          >
            Skopiuj z faktury
          </Button>
          </div>
          <Form.InputGroup>
            <Form.Input
              placeholder="NIP..."
              className='full-width'
              {...register(["address", "delivery_nip"])}
            />
            <Form.Input
              placeholder="Imię i nazwisko..."
              {...register(["address", "delivery_fullname"])}
            />
            <Form.Input
              placeholder="Nazwa firmy..."
              {...register(["address", "delivery_company"])}
            />
            <Form.Input
              placeholder="Adres..."
              className='full-width'
              {...register(["address", "delivery_address"])}
            />
            <Form.Input
              placeholder="Kod pocztowy..."
              {...register(["address", "delivery_postcode"])}
            />
            <Form.Input
              placeholder="Miasto..."
              {...register(["address", "delivery_city"])}
            />
          </Form.InputGroup>
        </Form.Group>
          <Button
          color='primary'
            onClick={() => update(id, pick(["address", "description"], data))}
          >
            Zapisz zmiany
          </Button>
        </div>
        <div
          style={{
            flexGrow: 1,
            padding: '0px 32px',
            width: '100%'
          }}
        >
          <h3>Notatki</h3>
            {data.notes
              .map(addIndexes)
              .filter((el) => el.type === "note")
              .map((note, ind) => (
                <div key={note.id} className='relative pb-4'>
                  <span
                  className='absolute top-0 right-0 text-xs cursor-pointer'
                    onClick={() => {
                      removeNote(note.id);
                      toRefetch();
                    }}
                  >
                    usuń
                  </span>

                  <div className='text-md font-bold'>{note.user.login}</div>
                  <div className='text-sm font-extralight'>{dayjs(note.created_at).format('YYYY-MM-DD HH:mm')}</div>
                  <textarea
                  placeholder='Treść notatki...'
                  className='w-full p-4 mt-4'
                    onBlur={() => {
                      updateNote(note.id, {
                        content: note.content,
                      });
                    }}
                    {...register(["notes", note.ind, "content"])}
                  />
                </div>
              ))}
          <span
          className='text-sm cursor-pointer'
            onClick={() => {
              createNote({
                content: "",
                done: false,
                contact_id: id,
                type: "note",
              });
              toRefetch();
            }}
          >
            + dodaj notatkę
          </span>
        </div>
        <div
          style={{
            flexGrow: 1,
            padding: '0px 32px',
            width: '100%'
          }}
        >
          <h3>Zadania</h3>
          {data.notes
            .map(addIndexes)
            .filter((el) => el.type === "task")
            .map((note) => (
              <div key={note.id} className='relative pb-4'>
                  <span
                  className='absolute top-0 right-0 text-xs cursor-pointer'
                    onClick={() => {
                      removeNote(note.id);
                      toRefetch();
                    }}
                  >
                    usuń
                  </span>
                  <div className='text-md font-bold'>{note.user.login}</div>
                  <div className='text-sm font-extralight'>{dayjs(note.created_at).format('YYYY-MM-DD HH:mm')}</div>
                  <textarea
                  placeholder='Treść zadania...'
                  className='w-full p-4 mt-4'
                    onBlur={() => {
                      updateNote(note.id, {
                        content: note.content,
                      });
                    }}
                    {...register(["notes", note.ind, "content"])}
                  />
                <br />
                <div className='py-2 flex'>
                <div className='w-full'>
                <label>Czy zrobione
<br/>                <input
                  type="checkbox"
                  checked={note.done}
                  onChange={(e) => {
                    updateNote(note.id, {
                      done: e.target.checked,
                    });
                    toRefetch();
                  }}
                />
                </label>
                </div>
                <div className='w-full'>
                  Data
                <Datetime
                  {...register(["notes", note.ind, "stringDate"])}
                  onClose={() => {
                    updateNote(note.id, {
                      stringDate: note.stringDate,
                    });
                  }}
                  utc={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                />
                </div>
                </div>
              </div>
            ))}
          <span
          className='text-sm cursor-pointer'
          onClick={() => {
              createNote({
                content: "",
                done: false,
                contact_id: id,
                type: "task",
              });
              toRefetch();
            }}
          >
            + dodaj zadanie
          </span>
        </div>
      </div>
    </div>
  );
};

import { keys } from "ramda";
import React, { useEffect, useState } from "react";
import ky from "ky";
import { microAddress } from "../services/consts";
import ICAL from "ical.js";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useResource } from "../services/engine";

const loadCalendar = async (set) => {
  const data = await ky.get(microAddress + "/calendar").text();
  console.log(data);
  const jcalData = ICAL.parse(data);
  set(
    jcalData[2]
      .filter((el) => el[0] === "vevent")
      .map((event) => {
        const data = event[1];
        const dtstart = data.find((el) => el[0] === "dtstart")[3];
        const dtend = data.find((el) => el[0] === "dtend")[3];
        const summary = data.find((el) => el[0] === "summary")[3];
        return {
          dtstart,
          dtend,
          summary,
        };
      })
  );
};

export default ({ jwtData }) => {
  const [eventData, setEventData] = useState(false);
  const { listData } = useResource(
    "notes",
    `
    stringDate
    content
  `,
    `id`,
    {
      where: {
        done: { _eq: false },
        type: { _eq: "task" },
        user_id: {
          _eq: jwtData["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
        },
      },
    }
  );
  useEffect(() => {
    loadCalendar(setEventData);
  }, []);

  console.log(listData);

  if (!eventData || !listData) return null;

  console.log(
    eventData
      .map((event) => ({
        title: event.summary,
        start: event.dtstart,
        end: event.dtend,
      }))
      .concat(
        listData.map((note) => ({
          title: note.content,
          start: (note.stringDate || dayjs().format('YYYY-MM-DD HH:mm:ss')).slice(0, 19),
        }))
      )
  );
  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin]}
        events={eventData
          .map((event) => ({
            title: event.summary,
            start: event.dtstart,
            end: event.dtend,
          }))
          .concat(
            listData.map((note) => ({
              title: note.content,
              start: (note.stringDate || dayjs().format('YYYY-MM-DD HH:mm:ss')).slice(0, 19),
            }))
          )}
        initialView="dayGridMonth"
      />
    </>
  );
};
